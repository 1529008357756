<template>
  <div class="register">
    <Breadcrumb :list="breadcrumbList" />
    <div class="register-form">
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">昵称</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.user_name" placeholder=" " />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">手机号</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.phone" placeholder=" " />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>邮箱注册（选填）</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.mail" placeholder=" " />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">所在省市</span>
        </div>
        <div class="form-item-right">
          <el-select
            v-model="form.province"
            placeholder="省"
            @change="changeProvince"
          >
            <el-option
              v-for="item in areaList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select class="input-extra" v-model="form.city" placeholder="市">
            <el-option
              v-for="item in cityList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>职业属性（选填）</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.position" placeholder=" " />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>单位名称（选填）</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.company" placeholder=" " />
        </div>
      </div>
      <!-- <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">接受最新动态推送（是否）</span>
        </div>
        <div class="form-item-right">
          <el-select v-model="form.receiving_news" placeholder=" ">
            <el-option :value="true" label="是">是</el-option>
            <el-option :value="false" label="否">否</el-option>
          </el-select>
        </div>
      </div> -->
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">密码</span>
        </div>
        <div class="form-item-right">
          <el-input
            v-model.trim="form.user_pwd"
            type="password"
            placeholder=" "
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">确认密码</span>
        </div>
        <div class="form-item-right">
          <el-input
            v-model.trim="form.user_pwd_inspect"
            type="password"
            placeholder=" "
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">验证码</span>
        </div>
        <div class="form-item-right code">
          <el-input v-model.trim="form.identification_code" placeholder=" " />
          <el-button
            type="primary"
            size="mini"
            @click="sendCode"
            :loading="codeLoading"
            :disabled="count !== 60"
            >{{ count === 60 ? "发送验证码" : count + "秒再试" }}</el-button
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left" style="width:100%;">
          <!-- <i class="icon-diamond"></i> -->
          <span style="margin-left: 0;"><i class="el-icon-warning"></i> 注册须知：待定</span>
        </div>
        <div class="form-item-right" style="width:0%;">
          <!-- <el-input v-model.trim="form.company" placeholder=" " /> -->
        </div>
      </div>
      <div class="form-item">
        <el-button
          type="primary"
          class="submit"
          @click="submit"
          :loading="submitLoading"
          >点击提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "_c/Breadcrumb.vue";
import { register, sendIdentificationCode } from "@/api/base.js";

export default {
  name: "Register",
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumbList: [], // 面包屑
      areaList: [], // 省市区
      form: {
        user_name: "",
        phone: "",
        mail: "",
        province: "",
        city: "",
        position: "",
        company: "",
        receiving_news: "",
        user_pwd: "",
        user_pwd_inspect: "",
        identification_code: "",
      },
      count: 60,
      timeId: null,
      codeLoading: false,
      submitLoading: false,
    };
  },
  computed: {
    cityList() {
      if (this.form.province) {
        let item = this.areaList.find((el) => el.code === this.form.province);
        return item.children || [];
      } else {
        return [];
      }
    },
  },
  created() {
    this.breadcrumbList = [
      { name: "Home", text: "首页", query: {} },
      { text: "注册" },
    ];
    let res = require("@/assets/area.json");
    this.areaList = res.data;
  },
  methods: {
    // 更改省
    changeProvince() {
      this.form.city = "";
    },
    // 发送验证码
    sendCode() {
      if (this.form.phone === "") {
        this.$message.warning("请输入手机号");
        return;
      }
      this.codeLoading = true;
      sendIdentificationCode({
        phone: this.form.phone,
        type: 1,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("发送成功！");
            this.timeId = setInterval(() => {
              if (this.count === 0) {
                clearInterval(this.timeId);
                this.timeId = null;
                this.count = 61;
              }
              this.count -= 1;
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.codeLoading = false;
        });
    },
    // 提交
    submit() {
      const {
        user_name,
        phone,
        province,
        city,
        // receiving_news,
        user_pwd,
        user_pwd_inspect,
        identification_code,
      } = this.form;
      if (
        !user_name ||
        !phone ||
        !province ||
        (this.cityList.length > 0 && !city) ||
        // !String(receiving_news) ||
        !user_pwd ||
        !user_pwd_inspect ||
        !identification_code
      ) {
        this.$message.warning("请填写必选项");
        return;
      }

      let item = this.areaList.find((el) => el.code === province);
      let provinceStr = item.name;
      let childItem = item.children.find((el) => el.code === city);
      let cityStr = childItem ? childItem.name : "";

      let data = {
        user_name,
        phone,
        province: provinceStr,
        city: cityStr,
        // receiving_news,
        user_pwd,
        user_pwd_inspect,
        identification_code,
        mail: this.form.mail,
        company: this.form.company,
        position: this.form.position,
      };
      this.submitLoading = true;
      register({ ...data })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("注册成功，快去登录吧！");
            this.$router.push({ name: "Login" });
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .register {
    padding: 0 18%;
    margin: 16px 0;
    .register-form {
      padding: 38px 8% 54px;
    }
    .form-item-left {
      font-size: 14px;
      width: 30%;
    }
    .form-item-right {
      width: 70%;
      display: flex;

      .el-input {
        width: 40%;
        margin-right: 4%;
      }
      .el-select {
        width: 40%;
        margin-right: 4%;
      }
      .el-button {
      }
    }
    .form-item {
      .el-button.submit {
        width: 50%;
        margin: 16px auto;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .register {
    padding: 0 2%;
    margin: 8px 0;
    .register-form {
      padding: 12px 8% 20px;
    }

    .form-item-left {
      font-size: 12px;
      width: 36%;
    }
    .form-item-right {
      width: 64%;
      .el-input {
      }
      .el-select {
        width: 50%;
      }
      .el-button {
      }
      &.code {
        .el-input {
          width: 50%;
          margin-right: 4%;
        }
        .el-button {
          width: 46%;
        }
      }
    }
    .form-item {
      .el-button.submit {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}
.register {
  background: #007cf9;
  .register-form {
    background: #fff;
    .form-item {
      display: flex;
      align-items: center;
      margin-top: 4%;
    }
    .form-item-left {
      .icon-diamond {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #5da8f3;
        transform: rotate(45deg);
      }
      span {
        margin-left: 4%;
        &.must {
          &::before {
            content: "*";
            color: red;
            margin-right: 4px;
          }
        }
      }
    }
    .form-item-right {
    }
  }
}
</style>